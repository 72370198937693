import {useState, useEffect} from "react";
import logo from '../img/close_right.svg'; // Tell webpack this JS file uses this image
import ReCAPTCHA from "react-google-recaptcha";
import {ReactSVG} from "react-svg";
import successSvg from "../img/success.svg";
import {useTranslation} from "react-i18next";
import useLocalStorage from "../hooks/useLocalStorage";
import {useRef} from "react";
import {API_URL} from "../utils/config";
import { ReactComponent as EyeOpen } from "../img/icons/eye.svg";
import { ReactComponent as EyeClose } from "../img/icons/eye-off.svg"; 

const RestorePasswordForm = () =>{
	const [login, setLogin] = useState('')
	const [password, setPassword] = useState('')
	const [error, setError] = useState('')
	const [captchaValue, setCaptchaValue] = useState(null)
	const [currentError, setCurrentError] = useState('')
	const currentDomain = window.location.hostname;
	const domainSegments = currentDomain.split('.');
	const lastSegment = domainSegments.pop();
	const [isDone, setIsDone] = useState(false)
	const [message, setMessage] = useState('')
	const {t} = useTranslation();
	const [language, setLanguage] = useLocalStorage('language', 'ru');
	const passwordInput = useRef(null);

  // show password
  const[showPassword, setShowPassword] = useState(false);

	const recaptchaChange = (value) =>{
		setCaptchaValue(value)
	}

	const queryString = window.location.search;

	function getParameterByName(name, url) {
		name = name.replace(/[\[\]]/g, "\\$&");
		var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	}

	const emailValue = getParameterByName('email', queryString);
	const secretValue = getParameterByName('secret', queryString);

	useEffect(()=>{
		if(emailValue){
			setLogin(emailValue)
			passwordInput.current.focus()
		}
	})

	const sendForm = () =>{
		setError('')
		if(captchaValue){
			let body = {
				email: login,
				newpass: password,
				recaptcha: captchaValue,
				secret:secretValue
			};

			fetch(`${API_URL}/auth/restore.pass`, {
				body:JSON.stringify(body),
				method: 'POST'
			}).then(response => response.json()).then(data => {
				if(data.status === true){
					setIsDone(true)
					setMessage(data.message)
				}else{
					setError(data.message)
				}
			})
		}else{
			setError(t('I_FORM_RECAPTCHA_CHECK'));
		}
	}


  // Show password toggle function

  const showPasswordToggle = () => {
    setShowPassword(!showPassword)
    if(showPassword){
      passwordInput.current.type = 'password'
    }else{
      passwordInput.current.type = 'text'
    }
  }

	return(
		<div className={`i_auth_form`}>
			<div className={`i_auth_form-title ${isDone ? 'hd' : ''}`}>
				<span>{t('I_FORM_RECOVERY_TITLE')}</span>
			</div>
			<div className={`i_auth_form-items ${isDone ? 'hd' : ''}`}>
				<div className="i_a_form-item">
					<input type="text" name={'USER_LOGIN'} maxLength={'50'} size={'17'} placeholder={'Email'} value={login}
					       onChange={(e)=>{setLogin(e.target.value)}}/>
				</div>
        <div className="i_a_form-item">
          <input type="password" name={'USER_PASSWORD'} maxLength={'225'} size={'17'}
                 placeholder={t('I_FORM_PASSWORD_NEW')} autoComplete={'off'}
                 onChange={(e) => {
                   setPassword(e.target.value);
                 }} value={password} ref={passwordInput} />
          <button
            className="i_a_form-item-eye"
            onClick={() => showPasswordToggle()}
          >{showPassword ? <EyeOpen /> : <EyeClose />}</button>
        </div>

        <ReCAPTCHA
          sitekey="6Le-OqcqAAAAAFEIomfag4G6t4-b15OGZvdQiwwS"
          onChange={recaptchaChange}
          hl={language}
        />
        <div className="i_a_form-item">
        <button className={'auth_btn'} onClick={sendForm}><span>{t('I_FORM_BTN_SAVE')}</span></button>
				</div>

				<div className="i_auth_form-error">
					<span>{error}</span>
				</div>
			</div>
			<div className={`i_form_success ${isDone ? '' : 'hd'}`}>
				<ReactSVG src={successSvg} beforeInjection={(svg) => {
					svg.classList.add('active')
				}}/>
				<span>{message}</span>
			</div>
		</div>
	)
}

export default RestorePasswordForm;